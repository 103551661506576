import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout/layout';
import axios from 'axios';
import NProgress from 'nprogress';
import { Fonts, MapStyle } from '../global.css';
import { StaticQuery, graphql } from 'gatsby';
import { allTrialsIds } from '../constants/config';

class Sitemap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scriptsLoading: true,
      urls: props.data.allStudyIds.nodes,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setState({
        scriptsLoading: false,
      });
    }

    this.loadData();
  }

  loadData() {
    NProgress.start();

    axios
      .get(allTrialsIds())
      .then(response => {
        NProgress.done();
        console.log(response.data);
        if (response.data && response.data.Success) {
          this.setState({
            urls: response.data.Data,
          });
        }
      })
      .catch(() => {
        NProgress.done();
      });
  }

  render() {
    const { data } = this.props;
    return this.state.scriptsLoading ? (
      ''
    ) : (
      <Layout meta={data.sitemapEnJson.seo}>
        <Fonts>
          <MapStyle>
            <div>
              <h2 className={'main-title'}>{data.sitemapEnJson.title}</h2>
              <div style={{ marginBottom: '40px' }}>
                <ul>
                  {data.pageMetadataJson.pages
                    .filter(page => page.url.indexOf('sitemap') < 0)
                    .map(url => (
                      <li className={'sitemap'} key={url.url}>
                        <a
                          href={
                            url.url.indexOf('http') === 0
                              ? url.url
                              : `/${url.pageLang}/${url.url}`
                          }
                        >
                          {url.menuTitle}
                        </a>
                      </li>
                    ))}
                  {this.state.urls.map(url => (
                    <li className={'sitemap'} key={url.Id}>
                      <a
                        href={`/study/${url.UniqueIdentifier}/`}
                        title={url.UniqueIdentifier}
                      >
                        {`${data.sitemapEnJson.detailsPage}: ${url.UniqueIdentifier}`}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </MapStyle>
        </Fonts>
      </Layout>
    );
  }
}

const SitemapWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        pageMetadataJson {
          pages {
            menuTitle
            url
            pageLang
          }
        }
        sitemapEnJson {
          title
          detailsPage
          seo {
            pageTitle
            pageDescription
          }
        }
        allStudyIds(limit: 9999) {
          nodes {
            UniqueIdentifier
            Id
          }
        }
      }
    `}
    render={data => <Sitemap data={data} {...props} />}
  />
);

export default SitemapWithQuery;

Sitemap.propTypes = {
  data: PropTypes.object.isRequired,
};
